@import url("https://fonts.googleapis.com/css2?family=Heebo:wght@400;500&family=Poppins:ital,wght@0,400;0,600;0,700;1,400;1,500;1,600&family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");
:root {
  /* --p-color: #233043; */
  --p-color: #48576c;
  --s-color: #e16e23;
  --b-color: #ffffff;
  --g-color: #b3b3b3;
  --a-color: #000000;
  --c-color: #666;
  --d-color: #001942;
  --h-color: #9da3ab;
  --i-color: #1877f2;
  --v-color: #ea2c59;
  --t-color: #1da1f2;
  --l-color: #007bb6;
  --h-family: "Montserrat", sans-serif;
  --p-family: "poppins";
}

.clearfix {
  content: "";
  clear: both;
  display: table;
}

.my-container {
  max-width: 1500px;
}

p {
  font-family: var(--p-family);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--h-family);
}

/* -- navbar -- */

.navbar {
  background-color: var(--b-color) !important;
}

.logo {
  height: 80px;
}

.logo-img {
  height: 100%;
  width: 100%;
  padding: 10px 25px;
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
}

.nav-link {
  text-transform: uppercase;
  /* padding: 30px 0px; */
  font-size: 16px;
  font-weight: 600;
  display: inline-block;
  color: var(--b-color) !important;
  border: 1px solid var(--p-color);
}
.nav-link2 {
  text-transform: uppercase;
  /* padding: 30px 0px; */
  font-size: 16px;
  font-weight: 600;
  display: inline-block;
  color: var(--p-color) !important;
  text-decoration: none;
}
.nav-footer {
}

.nav-link:hover {
  color: var(--b-color) !important;
  border: 1px solid var(--b-color);
}

.nav-item {
  text-decoration: none !important;
  padding: 0 10px;
}

.navbar-nav {
  padding-left: 80px;
}

.dropdown-menu {
  text-transform: uppercase;
  background-color: var(--p-color) !important;
  border-top: 3px solid var(--s-color);
  border-radius: 0;
}

.dropdown-item {
  font-weight: 600;
  color: var(--b-color) !important;
}

.btn-module {
  background-color: var(--s-color) !important;
  border-width: 0px !important;
  border-radius: 50px;
  box-shadow: 6px 6px 18px 0px rgb(0 0 0 / 30%);
  display: inline-block;
  padding: 0.3em 1em;
}

.contact-link {
  text-transform: capitalize;
  color: var(--b-color) !important;
  text-decoration: none;
  font-weight: 500;
  font-size: 20px;
  font-family: var(--p-family);
}

.btn-module:hover {
  background-color: var(--p-color) !important;
}

.fa-arrow-right {
  margin-left: 5px;
  display: none !important;
  opacity: 0;
}

.contact-link:hover .fa-arrow-right {
  display: inline !important;
  opacity: 1 !important;
  transition: all 3s linear;
}

@media only screen and (max-width: 992px) {
  .navbar-btn {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .dropdown-menu {
    visibility: visible !important;
    display: block !important;
    padding-left: 10px;
  }
}

/* -- mainbanner -- */

.carousel-item {
  height: 650px;
  width: 100%;
  overflow: hidden;
}

.carousel-img {
  height: 100%;
  object-fit: cover;
}

.carousel-caption {
  top: 30%;
  bottom: 70%;
}

.carousel-caption h2 {
  font-weight: bold;
  font-size: 46px;
  font-family: var(--h-family);
}

/* -- secondbanner-- */

.blurb-content-card {
  background-color: #e9e9e9 !important;
  border-radius: 15px;
  text-align: center;
  margin: 15px;
  padding-bottom: 20px;
}

.blurb-content {
  padding: 15px;
}

.blurb-img {
  height: 90px;
  width: 90px;
  margin: auto;
  margin-bottom: 10px;
}

.blurb-main-img {
  height: 100%;
  width: 100%;
}

.blurb-row {
  position: relative;
  /* bottom: 60px; */
  z-index: 10;
  margin-left: 20px;
  margin-right: 20px;
}

.blurb-container .module-header {
  color: var(--p-color) !important;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  padding: 10px;
  line-height: 1em;
}

.blurb-wrapper {
  color: var(--p-color) !important;
}

.blurb-wrapper p {
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.btn-module a {
  font-size: 15px;
}

/* @media screen and (max-width: 660px) {
    .blurb-content-card {
        height: 560px;
    }
} */

/* -- offer -- */

.offer-content {
  text-align: center;
}

.offer-content h2 {
  font-size: 40px;
  font-weight: bold;
  padding-bottom: 30px;
  line-height: 1em;
}

.offer-text p {
  font-size: 16px;
}

.offer-card {
  height: 350px;
  width: 100%;
  border-radius: 12px;
  padding: 160px 20px 20px 20px;
  margin-top: 30px;
  background-position: center center;
  background-size: cover;
}

.second-card {
  padding: 0px 100px 0px 100px !important;
}

.offer-blurb h4 {
  font-size: 18px;
  font-weight: bold;
  color: var(--b-color) !important;
}

.offer-blurb p {
  color: var(--g-color) !important;
  font-weight: 600;
}

.content-container {
  margin: 0 45px;
}

@media only screen and (max-width: 1200px) {
  .offer-card {
    padding: 120px 20px 20px 20px;
  }
}

@media only screen and (max-width: 1400px) {
  .second-card {
    padding: 0 !important;
  }
}

@media only screen and (max-width: 436px) {
  .offer-blurb-description p {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    /* number of lines to show */
    line-clamp: 3;
    -webkit-box-orient: vertical;
  }
}

/* -- homeblog -- */

/* .content-container {
    padding-top: 50px;
} */

.blog-content {
  text-align: center;
  margin-top: 60px;
}

.blog-header h2 {
  font-size: 40px;
  font-weight: bold;
  padding-bottom: 10px;
  line-height: 0.8em;
}

.blog-description p {
  color: var(--d-color) !important;
  font-size: 16px;
  padding: 0 270px 0 270px !important;
  font-weight: 600;
}

@media only screen and (max-width: 991px) {
  .blog-description p {
    padding: 0 !important;
  }
}

.blog-card {
  border-radius: 12px 12px 12px 12px;
  overflow: hidden;
  margin-bottom: 30px;
  box-shadow: 6px 6px 18px 0px rgb(0 0 0 / 30%);
  border: 1px solid #d8d8d8;
}

.blog-img {
  /* border-radius: 12px 12px 12px 12px; */
  min-width: 100%;
  max-width: 100%;
  height: auto;
  position: relative;
  box-shadow: 6px 6px 18px 0px rgba(0, 0, 0, 0.3);
}

.blog-card-content {
  text-align: left;
  padding: 19px;
  background-color: #fff;
}

.entry-title {
  margin-top: 10px;
  align-items: left;
  word-wrap: break-word;
}

.entry-title h2 {
  line-height: 0.8rem !important;
}

.entry-title > a {
  text-decoration: none !important;
  font-size: 18px;
  line-height: 0.5em;
  color: var(--a-color) !important;
  font-weight: 650;
}

.post-meta {
  font-size: 14px;
  margin-bottom: 6px;
  color: var(--c-color);
  line-height: 1.7em;
  font-weight: 600;
}

.post-meta > a {
  text-decoration: none;
  color: var(--c-color) !important;
}

.alignleft > a {
  text-decoration: none;
  color: var(--s-color);
  line-height: 1.7em;
  font-weight: 600;
}

/* -- footer -- */

.first-footer {
  background-color: var(--p-color) !important;
  padding-bottom: 50px;
  padding-top: 50px;
}

.footer-container {
  padding-left: 30px;
}

.footer-content {
  /* padding-right: 30px; */
  margin-top: 50px;
}

.footer-content h2 {
  font-weight: 700;
  line-height: 1.2em;
  color: var(--b-color) !important;
  font-size: 23px;
  text-align: left;
}

.footer-content p {
  line-height: 1.7em;
  font-weight: 500;
  /* color: var(--h-color); */
}

.footer-search-form {
  font-size: 18px;
  color: var(--b-color);
  font-weight: bold;
  padding-bottom: 10px;
  line-height: 1em;
}

.input-form {
  border-width: 2px !important;
  border-color: #e16e23 !important;
  border-radius: 16px 16px 16px 16px;
}

.form-control {
  background-color: #233043;
  color: #ffffff;
  padding-top: 0.715em !important;
  padding-right: 0.715em !important;
  padding-bottom: 0.715em !important;
  padding-left: 0.715em !important;
  border-color: #e16e23 !important;
  height: auto;
  min-height: 0;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--a-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--s-color);
  border: 1px solid var(--s-color);
  border-radius: 0.25rem;
}
.footer-inner {
  line-height: 3;
}
.footer-inner h4 {
  /* padding-bottom: 10px; */
  line-height: 1em;
  font-weight: bold;
  font-size: 18px;
  color: var(--b-color) !important;
}
.footer-inner h3 {
  color: var(--b-color) !important;
  margin: 22px 0;
}
.footer-inner > p > a {
  color: var(--s-color) !important;
  text-decoration: none !important;
  line-height: 1.7em;
  font-weight: 550;
}
.footer-main {
  text-align: center;
}
.footer-nav-main {
  text-align: center !important;
  line-height: 0;
}
.footer-nav-main ul {
  padding: 0px;
}
.footer-nav-main ul > li {
  display: inline-block;
  list-style: none;
}
.social-media {
  text-align: center;
  overflow: hidden;
}

.social-media-container {
  display: flex;
  justify-content: center;
  position: relative;
  top: -25px;
}

.social-media-follow li {
  display: inline-block;
  margin-bottom: 8px;
  position: relative;
  margin: 0px 10px !important;
  line-height: 26px;
}

.social-icon {
  margin-right: 8px;
  display: inline-block;
  text-decoration: none;
  text-align: center;
  position: relative;
}

.social-media-icon > i {
  height: 25px;
  width: 25px;
  padding-top: 5px;
  border-radius: 50% !important;
  color: var(--b-color) !important;
  box-shadow: 6px 6px 18px 0px rgb(0 0 0 / 30%) !important;
}

.facebook {
  background-color: var(--i-color) !important;
}

.instagram {
  background-color: var(--v-color);
}

.twitter {
  background-color: var(--t-color);
}

.linked-in {
  background-color: var(--l-color);
}

.subscribe-form {
  width: 100%;
  margin-top: 10px;
  padding: 12px 10px;
  border-radius: 50px 50px 50px 50px;
  border-width: 1px;
  border-color: var(--p-color);
}

.subscribe-btn {
  text-align: center;
  margin-top: 15px;
  border: 1px solid var(--p-color);
  padding: 12px 10px;
  border-radius: 50px 50px 50px 50px;
  background-color: var(--s-color);
  border-width: 1px;
  color: #fff;
  font-weight: 700 !important;
  font-size: 14px;
}

.subscribe-form h2 {
  font-size: 24px;
  text-transform: capitalize;
}

.subscribe-btn:hover {
  background-color: var(--s-color) !important;
  border: none;
}

.subscribe-form a {
  text-decoration: none !important;
}

.subscribe-btn:hover .fa-arrow-right {
  display: inline !important;
  opacity: 1 !important;
  transition: all 3s linear;
}

.end-footer {
  max-width: 1920px;
  line-height: 1.7em;
  font-weight: 500;
  text-align: center;
  padding: 16px 0;
}

.end-footer-text {
  color: var(--b-color);
}

/* -- about -- */

.banner-img {
  width: 100%;
  height: 700px;
  background-repeat: no-repeat;
  background-size: cover;
}

.overlay {
  width: 100%;
  height: 100%;
  background: radial-gradient(
    circle at top left,
    rgba(35, 48, 67, 0.71) 60%,
    rgba(225, 110, 35, 0.72) 100%
  );
  display: flex;
  justify-content: center;
  align-items: center;
}

.slider-container-inner {
  text-align: center;
}

.slide-title {
  padding-bottom: 10px;
}

.slide-title > a {
  text-decoration: none;
  color: var(--b-color);
  font-size: 59px;
  font-weight: bold;
  font-style: normal;
}

.section-subtitle p {
  color: var(--h-color);
  font-weight: 600;
  line-height: 1.7em;
  font-size: 18px;
}

.about-content {
  padding-top: 24px;
}
.about-content-home{
    padding: 55px;
}
.about-content-home h6{
    color: var(--l-color);
    font-weight: bold;
    text-transform: capitalize;
}
.about-content-home h1{
    font-weight: bold !important;
    font-size: 38px;
    font-family: var(--h-family);
    line-height: 50px;
    margin: 16px 0;
    word-spacing: 12px;
}
.about-content-home p{
    opacity: 70%;
    line-height: 35px;
    text-align: justify;
}
.about-content h2 {
  font-weight: bold !important;
  font-size: 26px;
  padding-bottom: 10px;
  line-height: 1em;
}

.about-content p {
  line-height: 1.7em;
  font-weight: 500;
  padding-bottom: 1em;
}

blockquote {
  border-color: var(--s-color) !important;
  margin: 20px 0 30px;
  border-left: 5px solid;
  padding-left: 20px;
}

.blockquote-text {
  color: #e09900 !important;
  font-weight: bold;
  font-size: 18px;
  padding-bottom: 10px;
  line-height: 1em;
}

.accordion-header h2 {
  padding-bottom: 10px;
  line-height: 1em;
  font-size: 26px;
  font-weight: bold;
}

.accordion-module {
  padding-bottom: 30px;
}

.accordion-item {
  border-radius: 12px 12px 12px 12px !important;
  overflow: hidden;
  position: relative;
  padding: 20px;
  background-color: var(--s-color);
  margin: 10px 0px;
  border: 1px solid #d9d9d9;
  animation-duration: 0.2s;
}

.toggle-title {
  color: var(--b-color);
  position: relative;
  padding: 0 50px 0 0;
  font-weight: bold;
  font-size: 16px;
  line-height: 1em;
}

.toggle-content {
  padding: 15px 50px 0 0;
  position: relative;
  display: block;
}

.toggle-content p {
  font-size: 15px;
  color: var(--d-color);
  line-height: 1.7em;
  font-weight: 500;
}

.accordion-item.close {
  background-color: var(--p-color);
  color: var(--b-color);
}

.accordion-item.close > .toggle-title {
  color: var(--b-color);
}

.toggle-title .fa-circle-plus {
  position: absolute;
  right: 0;
  left: auto;
  font-size: 16px;
  color: var(--s-color);
}

.divider {
  padding-bottom: 80px;
}

.top-inside-divider {
  background-size: 100% 100px;
  top: 0;
  height: 100px;
  z-index: 10;
  transform: rotateX(180deg);
}

.divider-content {
  text-align: center;
}

.divider-header h3 {
  font-size: 40px;
  font-weight: bold;
  padding-bottom: 30px;
  line-height: 1em;
}

/* -- contact -- */

.overlay.contact {
  background: radial-gradient(
    circle at top left,
    rgba(35, 48, 67, 0.65) 0%,
    rgba(225, 110, 35, 0.42) 100%
  );
}

.contact {
  padding: 2% 0;
}

.phone {
  margin-bottom: 17.628%;
}

.phone > h4 {
  font-size: 18px;
  font-weight: bold;
  line-height: 1em;
}

.phone > h4 > a {
  color: var(--s-color);
  text-decoration: none;
}

.sales {
  padding-left: 40px;
}

.sales > h5 {
  font-size: 16px;
  font-weight: bold;
}

.sales > h6 > a {
  font-size: 15px;
  font-weight: bold;
  text-decoration: none;
  color: var(--s-color);
}

.wpb-element {
  margin-bottom: 30px;
}

.wpb-wrapper p {
  font-size: 15px;
  font-weight: 600;
}

.media-icon {
  background-color: none !important;
  color: #e16e23 !important;
  height: 32px;
  width: 25px;
  font-size: 16px;
  display: block;
  transition: color 0.3s;
  position: relative;
  z-index: 10;
}

.contact-page-icon {
  margin-right: 3px;
}

.contact-media ul {
  padding-left: 0rem !important;
}

.contact-container {
  display: flex;
}

.enquiry-heading h2 {
  text-align: center;
  font-size: 34px;
  font-weight: bold;
  padding-bottom: 10px;
  line-height: 1em;
}

.enquiry-form {
  border-radius: 12px 12px 12px 12px;
  overflow: hidden;
  background-color: #eee;
  width: 100%;
  border-width: 0;
  color: #999;
  font-size: 14px;
  padding: 12px !important;
}

.bottom-container {
  display: flex;
  justify-content: right;
  line-height: 1.7em;
  font-weight: 500;
}

.contact-right {
  display: inline-block;
  min-width: 105px;
  margin: 0;
  text-align: right;
}

.contact-captcha {
  border-radius: 12px 12px 12px 12px;
  overflow: hidden;
}

/* -- digital marketing -- */

.slide-description {
  padding: 0 16%;
}

.service_contents {
  margin-top: 34px;
}

.service_heading {
  font-family: var(--h-family);
  text-align: center;
}

.service_heading h2 {
  font-size: 26px;
  font-weight: bold;
  color: var(--a-color);
}

.service_card {
  margin-top: 34px;
}

.card_contents {
  background-color: var(--p-color);
  padding: 10px 20px;
  border-radius: 16px 16px 16px 16px;
  height: 397px;
  margin-bottom: 45px;
}

@media (max-width: 1199px) {
  .card_para p {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 10;
    /* number of lines to show */
    line-clamp: 10;
    -webkit-box-orient: vertical;
  }
}

.card_contents2 {
  background-color: var(--p-color);
  padding: 10px 20px;
  border-radius: 16px 16px 16px 16px;
  height: 500px;
  margin-bottom: 45px;
}

.card_contents3 {
  background-color: var(--p-color);
  padding: 10px 20px;
  border-radius: 16px 16px 16px 16px;
  height: 445px;
  margin-bottom: 45px;
}

.card_icon_contents {
  display: flex;
  justify-content: center;
}

.card_icon {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  background-color: var(--s-color);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 48px;
  margin: 20px;
}

.card_icon i {
  color: var(--b-color);
  text-align: center;
  font-size: 40px;
}

.card_heading {
  font-family: var(--h-family);
  text-align: center;
}

.card_heading h4 {
  font-size: 21px;
  color: var(--b-color);
}

.card_para {
  text-align: justify;
  font-family: var(--p-family);
}

.card_para p {
  line-height: 1.7em;
  font-weight: 500;
  font-size: 14px;
  color: var(--g-color);
}

.service_descriptions {
  margin-bottom: 35px;
}

.service_heading h3 {
  font-size: 40px;
  font-weight: bold;
  margin-bottom: 20px;
}

.service_all {
  margin-top: 20px;
}

.service_image {
  height: 300px;
  width: 100%;
}

.service_image img {
  height: 100%;
  width: 100%;
}

.desc p {
  font-family: var(--p-family);
  font-size: 14px;
  line-height: 1.7em;
  font-weight: 500;
}

.desc li {
  list-style-type: circle !important;
  list-style-position: inside;
  font-family: var(--p-family);
  font-size: 14px;
  line-height: 1.7em;
  font-weight: 500;
}

/* -- form -- */

.home-page-form {
  background-color: var(--p-color);
}

.form-enquiry-heading {
  color: var(--b-color);
}

.form-enquiry-text {
  /* color: #9ea3ac; */
  color: #c2c9d5;
}

.contact-field {
  margin-bottom: 2%;
}

@media (min-width: 981px) {
  .home-page-form {
    padding: 4% 0;
  }
  .form-enquiry-heading {
    margin-bottom: 2.75%;
  }
  .form-enquiry-text {
    margin-bottom: 2.75%;
  }
}

.bottom-inside-divider {
  height: 50px;
  width: 100%;
}

.divider-img {
  height: 100%;
  width: 100%;
}

/* -- portfolio -- */

.service_contents {
  text-align: center;
}

.section-title h2 {
  text-align: center;
  font-weight: 600;
  margin-bottom: 50px;
  margin-top: 130px;
  color: #0a3d56;
}

.home-service-card {
  margin-bottom: 50px;
}

.home-service-card-image {
  height: 200px;
  overflow: hidden;
  margin-bottom: 15px;
}

.img-fluid {
  max-width: 100%;
  height: 100%;
}

.home-service-card h6 {
  text-transform: uppercase;
  font-weight: 700;
  color: #0a3d56;
  font-size: 16px;
}

.home-service-card a {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
  color: #2295ba;
}

/* -- blog -- */

.more-link {
  text-transform: uppercase;
  font-size: 16px;
  color: var(--s-color);
  text-decoration: none;
  font-weight: 600;
  font-size: 16px;
}

.more-link:hover {
  color: var(--s-color);
}

.author.vcard a {
  text-transform: uppercase;
  font-size: 13px;
  color: #666 !important;
  text-decoration: none;
}

.blog-page {
  padding: 2% 0;
}

/*  Slilder Banner  */
.main-slider {
  background-color: #f8f8f8;
  padding: 42px 0;
}
.slider-content {
  height: 100%;
  width: 100%;
  overflow: hidden;
}
.slider-content img {
  height: 80px;
  width: 80px;
  /* filter: contrast(0%); */
  /* opacity: .7; */
  object-fit: contain;
}
.slick-prev:before {
  color: var(--d-color) !important;
}
.slick-next:before {
  color: var(--d-color) !important;
}
.about-main{
    padding: 64px 0;
}
.about-img {
  height: 100%;
  width: 100%;
  overflow: hidden;
}
.about-img img {
  height: 400px;
  width: 100%;
  object-fit: cover;
}
.cart-main{
    text-align: center;
}
.cart-content{
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    padding: 30px 20px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.cart-content h4{
    font-weight: 600;
    color: var(--b-color);
}
.cart-content p{
    text-align: justify;
    color: var(--b-color);
}
.card-body{
    padding-bottom: 42px;
}
.card-icon img{
    height: 40px;
    width: 40px;
    object-fit: cover;
}
.card-btn{
    margin-top: 5px;
    border: none;
    padding: 4px 8px;
    background-color: var(--l-color);
    
}
.card-a{
    text-decoration: none;
    color: var(--b-color) !important;
}
.card-btn:hover{
    background-color: var(--s-color);
}

